<template>
  <div class="addApplication">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="应用名称"
        :rules="[
          { required: true, message: '请输入应用名称', trigger: 'blur' },
        ]"
        prop="modelTitle"
      >
        <v-input
          placeholder="请输入应用名称"
          :maxlength="8"
          v-model="form.modelTitle"
          disabled
          :width="250"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="应用图标"
        :rules="[
          {
            required: true,
            message: '请上传应用图标',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="modelIconPic"
      >
        <v-upload :limit="1" disabled :imgUrls.sync="form.modelIconPic" />
      </el-form-item>
      <el-form-item
        label="封面图"
        :rules="[
          {
            required: false,
            message: '请上传封面图',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="coverPic"
      >
        <v-upload :limit="1" disabled :imgUrls.sync="form.coverPic" />
      </el-form-item>
      <el-form-item
        label="介绍图片"
        :rules="[
          {
            required: true,
            message: '请上传介绍图片',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="descPic"
      >
        <v-upload :limit="9" disabled :imgUrls.sync="form.descPic" />
      </el-form-item>
      <el-form-item
        label="应用分类"
        :rules="[
          { required: true, message: '请选择应用分类', trigger: 'blur' },
        ]"
        prop="categoryId"
      >
        <v-select :options="kindsList" disabled v-model="form.categoryId" />
      </el-form-item>
      <el-form-item
        label="应用简介"
        :rules="[
          { required: true, message: '请输入应用简介', trigger: 'blur' },
        ]"
        prop="modelDesc"
      >
        <v-input
          type="textarea"
          disabled
          placeholder="请输入应用简介"
          v-model="form.modelDesc"
          :width="250"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="适用社区"
        :rules="[
          { required: false, message: '请输入适用社区', trigger: 'blur' },
        ]"
        prop="targetTenantTxt"
      >
        <span>{{ form.targetTenantTxt }}</span>
      </el-form-item>
      <el-form-item
        label="是否需要实名"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
        prop="targetIsRealName"
      >
        <v-radio
          :radioObj="isRealRadioObj"
          v-model="form.targetIsRealName"
          @change="change"
          :disabled="true"
        ></v-radio>
      </el-form-item>
      <el-form-item
        label="应用类型"
        :rules="[
          { required: true, message: '请选择应用类型', trigger: 'blur' },
        ]"
        prop="targetType"
      >
        <v-select disabled :options="pagesType" v-model="form.targetType" />
      </el-form-item>
      <el-form-item
        label="是否增加数据埋点"
        :rules="[{ required: false, message: '请选择', trigger: 'blur' }]"
        prop="targetIsBuriedPoint"
      >
        <v-radio
          disabled
          :radioObj="radioObj"
          v-model="form.targetIsBuriedPoint"
          @change="change"
        ></v-radio>
      </el-form-item>
      <el-form-item
        label="数据埋点类型"
        v-if="form.targetIsBuriedPoint == 1 && form.appType == 0"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
        prop="targetBuriedPointTypeId"
      >
        <v-select
          :options="buriedPointList"
          v-model="form.targetBuriedPointTypeId"
          disabled
        />
      </el-form-item>
      <el-form-item
        label="数据埋点类型"
        v-if="form.targetIsBuriedPoint == 1 && form.appType != 0"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
        prop="targetBuriedPointTypeId"
      >
        <span>{{ form.targetBuriedPointTypeTitle }}</span>
      </el-form-item>
      <template v-if="form.targetType == 1">
        <el-form-item
          label="小程序页面路径"
          :rules="[
            {
              required: true,
              message: '请输入小程序页面路径',
              trigger: 'blur',
            },
          ]"
          prop="targetUrl"
        >
          <v-input
            placeholder="请输入小程序页面路径"
            v-model="form.targetUrl"
            disabled
            :width="250"
          ></v-input
          ><span class="info">(例如："/pages/index/index")</span>
        </el-form-item>
      </template>
      <template v-if="form.targetType == 2">
        <el-form-item
          label="h5页面路径"
          :rules="[
            {
              required: true,
              message: '请输入h5页面路径',
              trigger: 'blur',
            },
          ]"
          prop="targetUrl"
        >
          <v-input
            placeholder="请输入h5页面路径"
            v-model="form.targetUrl"
            :width="350"
            disabled
          ></v-input
          ><span class="info">(例如："/futureCreateWork/home")</span>
        </el-form-item>
      </template>
      <template v-if="form.targetType == 3">
        <el-form-item
          label="第三方h5页面路径"
          :rules="[
            {
              required: true,
              message: '请输入第三方h5页面路径',
              trigger: 'blur',
            },
          ]"
          prop="targetUrl"
        >
          <v-input
            placeholder="请输入第三方h5页面路径"
            v-model="form.targetUrl"
            :width="350"
            disabled
          ></v-input
          ><span class="info"
            >(例如："https://dev.zhuneng.cn/zlb-h5-yljk/pages/entrance/thirdParty")</span
          >
        </el-form-item>
      </template>
      <template v-if="form.targetType == 4">
        <el-form-item
          label="第三方小程序appid"
          :rules="[
            {
              required: true,
              message: '请输入第三方小程序appid',
              trigger: 'blur',
            },
          ]"
          prop="targetAppid"
        >
          <v-input
            placeholder="请输入第三方小程序appid"
            v-model="form.targetAppid"
            disabled
            :width="250"
          ></v-input
          ><span class="info">(例如："wx0cd4b682f7e7f72f")</span>
        </el-form-item>
        <el-form-item
          label="第三方小程序页面路径"
          :rules="[
            {
              required: true,
              message: '请输入第三方小程序页面路径',
              trigger: 'blur',
            },
          ]"
          prop="targetUrl"
        >
          <v-input
            placeholder="请输入第三方小程序页面路径"
            v-model="form.targetUrl"
            disabled
            :width="350"
          ></v-input
          ><span class="info"
            >(例如："/pages/home/home?XKHospitalCode=XK33021201fGdxixsF")</span
          >
        </el-form-item>
      </template>
      <el-form-item
        label="应用的管理后台页面"
        :rules="[
          {
            required: false,
            message: '请输入应用的管理后台页面路径',
            trigger: 'blur',
          },
        ]"
        prop="managerUrl"
      >
        <v-input
          placeholder="请输入应用的管理后台页面路径"
          v-model="form.managerUrl"
          :width="350"
          disabled
        ></v-input>
      </el-form-item>
      <el-form-item
        label="排序"
        :rules="[{ required: true, message: '请输入排序', trigger: 'blur' }]"
        prop="sortIndex"
      >
        <v-input
          placeholder="请输入排序"
          v-model="form.sortIndex"
          :width="250"
          type="number"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="是否推荐"
        :rules="[
          { required: true, message: '请选择是否推荐', trigger: 'blur' },
        ]"
        prop="isRecommend"
      >
        <v-select :options="isRecommendList" v-model="form.isRecommend" />
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import JSONEditor from "jsoneditor";
import {
  appDetailUrl,
  deveAddApplyUrl,
  getBuriedPointUrl,
  kindsListUrl,
} from "./api";
import { downloadHelper } from "@/utils/func";
import { pagesType, inType, isRecommendList } from "./map";
export default {
  name: "addApplication",
  data() {
    let _this = this;
    return {
      isRecommendList,
      kindsList: [],
      inType,
      isRealRadioObj: [
        { name: "0", value: "否" },
        { name: "1", value: "是" },
      ],
      isHomeRadioObj: [
        { name: "0", value: "否" },
        { name: "1", value: "是" },
      ],
      radioObj: [
        { name: "0", value: "否" },
        { name: "1", value: "是" },
      ],
      chapterList: [],
      submitConfig: {
        queryUrl: appDetailUrl,
        submitUrl: deveAddApplyUrl,
      },
      teacherSignList: [],
      form: {
        appType: "",
        targetBuriedPointTypeTitle: "",
        id: "",
        coverPic: "",
        descPic: "",
        managerUrl: "",
        targetIsRealName: "0",
        categoryId: "",
        modelTitle: "",
        modelIconPic: "",
        modelDesc: "",
        targetType: "",
        sortIndex: 1,
        isRecommend: 0,
        sourceType: "",
        targetUrl: "",
        targetAppid: "",
        targetIsBuriedPoint: "0",
        targetBuriedPointTypeId: "",
        targetIsAuth: "1",
        targetExtraData: {},
        insertUser: "",
        updateUser: "",
        isHomeShow: "",
        targetTenantIds: "",
        targetTenantNames: [],
        targetTenantTxt: "",
      },
      buriedPointList: [],
      pagesType,

      editor: null,
    };
  },
  mounted() {
    const { id, communityId } = this.$route.query;
    if (id !== undefined) {
      this.$refs.formPanel.getData({ id: id });
      this.form.updateUser = this.$store.state.app.userInfo.userId;
    } else {
      this.form.insertUser = this.$store.state.app.userInfo.userId;
    }
    this.$setBreadList(id ? "编辑" : "新增");
    this.getBuriedPointList();
    this.getKindsList();
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
    }
  },
  methods: {
    toLink() {
      let obj = {};
      obj.src = "/static/txt/CnVUPQ4jS0.txt";
      obj.fileName = "CnVUPQ4jS0.txt";
      downloadHelper.downloadBySrc(obj);
    },
    async getKindsList() {
      this.kindsList = [];
      let res = await this.$axios.get(`${kindsListUrl}`);
      if (res.code === 200) {
        if (res.data) {
          res.data.forEach((item) => {
            item.label = item.title;
            item.value = item.id;
            this.kindsList.push(item);
          });
        }
      }
    },
    onChangeJSON(json) {
      console.log(json);
    },
    initJsonEditer() {
      let _this = this;
      let container = document.getElementById("jsoneditor");
      let options = {
        mode: "code",
        indentation: 2,
        search: true,
        enableTransform: false,
      };
      _this.editor = new JSONEditor(container, options);
      this.editor && this.editor.set(this.form.targetExtraData);
    },
    getCode() {
      console.log(this.jsonData, this.editor.get());
    },
    async getBuriedPointList() {
      this.buriedPointList = [];
      let res = await this.$axios.get(`${getBuriedPointUrl}`);
      if (res.code === 200) {
        if (res.data) {
          res.data.forEach((item) => {
            item.label = item.typeName;
            item.value = item.id;
            this.buriedPointList.push(item);
          });
        }
      }
    },
    change() {},

    submitSuccess(data) {
      return true;
    },

    submitBefore() {
      return true;
    },

    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
      if (this.form.targetTenantNames) {
        this.form.targetTenantTxt = this.form.targetTenantNames.join(",");
      }
      this.form.targetIsBuriedPoint = String(data.targetIsBuriedPoint);
      this.form.targetIsRealName = String(data.targetIsRealName);
      this.form.targetBuriedPointTypeId = data.targetBuriedPointTypeId
        ? String(data.targetBuriedPointTypeId)
        : data.targetBuriedPointTypeId;
      if (
        !data.targetExtraData ||
        data.targetExtraData == "" ||
        data.targetExtraData == undefined
      ) {
        console.log(data.targetExtraData, "++++++");
        this.form.targetExtraData = {};
      } else {
        this.form.targetExtraData = data.targetExtraData;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.addApplication {
  box-sizing: border-box;
  height: 100%;
  /deep/ .el-form-item__label {
    width: 190px !important;
  }
  /deep/ textarea {
    height: 150px;
  }
  .info {
    margin-left: 10px;
  }
  .extraData {
    width: 600px;
    height: 500px;
    #jsoneditor {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
<style scoped>
@import "~jsoneditor/dist/jsoneditor.min.css";
.IotAgentServiceConfig-wrapper {
  background-color: white;
  overflow: hidden;
}

.search-wrapper {
  margin: 20px;
  display: flex;
}

.pull-right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

#jsoneditor {
  margin: 20px 20px 20px 20px;
  width: calc(100% - 40px);
  height: calc(100% - 160px);
}

#jsoneditor >>> .jsoneditor-menu {
  background-color: #409eff;
  border-bottom: 1px solid #409eff;
}

#jsoneditor >>> .jsoneditor.jsoneditor-mode-text {
  border: thin solid #409eff;
}

#jsoneditor >>> .jsoneditor-poweredBy {
  display: none;
}
</style>
