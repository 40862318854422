var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"addApplication"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"应用名称","rules":[
          { required: true, message: '请输入应用名称', trigger: 'blur' },
        ],"prop":"modelTitle"}},[_c('v-input',{attrs:{"placeholder":"请输入应用名称","maxlength":8,"disabled":"","width":250},model:{value:(_vm.form.modelTitle),callback:function ($$v) {_vm.$set(_vm.form, "modelTitle", $$v)},expression:"form.modelTitle"}})],1),_c('el-form-item',{attrs:{"label":"应用图标","rules":[
          {
            required: true,
            message: '请上传应用图标',
            trigger: ['change', 'blur'],
          },
        ],"prop":"modelIconPic"}},[_c('v-upload',{attrs:{"limit":1,"disabled":"","imgUrls":_vm.form.modelIconPic},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "modelIconPic", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "modelIconPic", $event)}}})],1),_c('el-form-item',{attrs:{"label":"封面图","rules":[
          {
            required: false,
            message: '请上传封面图',
            trigger: ['change', 'blur'],
          },
        ],"prop":"coverPic"}},[_c('v-upload',{attrs:{"limit":1,"disabled":"","imgUrls":_vm.form.coverPic},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "coverPic", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "coverPic", $event)}}})],1),_c('el-form-item',{attrs:{"label":"介绍图片","rules":[
          {
            required: true,
            message: '请上传介绍图片',
            trigger: ['change', 'blur'],
          },
        ],"prop":"descPic"}},[_c('v-upload',{attrs:{"limit":9,"disabled":"","imgUrls":_vm.form.descPic},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "descPic", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "descPic", $event)}}})],1),_c('el-form-item',{attrs:{"label":"应用分类","rules":[
          { required: true, message: '请选择应用分类', trigger: 'blur' },
        ],"prop":"categoryId"}},[_c('v-select',{attrs:{"options":_vm.kindsList,"disabled":""},model:{value:(_vm.form.categoryId),callback:function ($$v) {_vm.$set(_vm.form, "categoryId", $$v)},expression:"form.categoryId"}})],1),_c('el-form-item',{attrs:{"label":"应用简介","rules":[
          { required: true, message: '请输入应用简介', trigger: 'blur' },
        ],"prop":"modelDesc"}},[_c('v-input',{attrs:{"type":"textarea","disabled":"","placeholder":"请输入应用简介","width":250},model:{value:(_vm.form.modelDesc),callback:function ($$v) {_vm.$set(_vm.form, "modelDesc", $$v)},expression:"form.modelDesc"}})],1),_c('el-form-item',{attrs:{"label":"适用社区","rules":[
          { required: false, message: '请输入适用社区', trigger: 'blur' },
        ],"prop":"targetTenantTxt"}},[_c('span',[_vm._v(_vm._s(_vm.form.targetTenantTxt))])]),_c('el-form-item',{attrs:{"label":"是否需要实名","rules":[{ required: true, message: '请选择', trigger: 'blur' }],"prop":"targetIsRealName"}},[_c('v-radio',{attrs:{"radioObj":_vm.isRealRadioObj,"disabled":true},on:{"change":_vm.change},model:{value:(_vm.form.targetIsRealName),callback:function ($$v) {_vm.$set(_vm.form, "targetIsRealName", $$v)},expression:"form.targetIsRealName"}})],1),_c('el-form-item',{attrs:{"label":"应用类型","rules":[
          { required: true, message: '请选择应用类型', trigger: 'blur' },
        ],"prop":"targetType"}},[_c('v-select',{attrs:{"disabled":"","options":_vm.pagesType},model:{value:(_vm.form.targetType),callback:function ($$v) {_vm.$set(_vm.form, "targetType", $$v)},expression:"form.targetType"}})],1),_c('el-form-item',{attrs:{"label":"是否增加数据埋点","rules":[{ required: false, message: '请选择', trigger: 'blur' }],"prop":"targetIsBuriedPoint"}},[_c('v-radio',{attrs:{"disabled":"","radioObj":_vm.radioObj},on:{"change":_vm.change},model:{value:(_vm.form.targetIsBuriedPoint),callback:function ($$v) {_vm.$set(_vm.form, "targetIsBuriedPoint", $$v)},expression:"form.targetIsBuriedPoint"}})],1),(_vm.form.targetIsBuriedPoint == 1 && _vm.form.appType == 0)?_c('el-form-item',{attrs:{"label":"数据埋点类型","rules":[{ required: true, message: '请选择', trigger: 'blur' }],"prop":"targetBuriedPointTypeId"}},[_c('v-select',{attrs:{"options":_vm.buriedPointList,"disabled":""},model:{value:(_vm.form.targetBuriedPointTypeId),callback:function ($$v) {_vm.$set(_vm.form, "targetBuriedPointTypeId", $$v)},expression:"form.targetBuriedPointTypeId"}})],1):_vm._e(),(_vm.form.targetIsBuriedPoint == 1 && _vm.form.appType != 0)?_c('el-form-item',{attrs:{"label":"数据埋点类型","rules":[{ required: true, message: '请选择', trigger: 'blur' }],"prop":"targetBuriedPointTypeId"}},[_c('span',[_vm._v(_vm._s(_vm.form.targetBuriedPointTypeTitle))])]):_vm._e(),(_vm.form.targetType == 1)?[_c('el-form-item',{attrs:{"label":"小程序页面路径","rules":[
            {
              required: true,
              message: '请输入小程序页面路径',
              trigger: 'blur',
            },
          ],"prop":"targetUrl"}},[_c('v-input',{attrs:{"placeholder":"请输入小程序页面路径","disabled":"","width":250},model:{value:(_vm.form.targetUrl),callback:function ($$v) {_vm.$set(_vm.form, "targetUrl", $$v)},expression:"form.targetUrl"}}),_c('span',{staticClass:"info"},[_vm._v("(例如：\"/pages/index/index\")")])],1)]:_vm._e(),(_vm.form.targetType == 2)?[_c('el-form-item',{attrs:{"label":"h5页面路径","rules":[
            {
              required: true,
              message: '请输入h5页面路径',
              trigger: 'blur',
            },
          ],"prop":"targetUrl"}},[_c('v-input',{attrs:{"placeholder":"请输入h5页面路径","width":350,"disabled":""},model:{value:(_vm.form.targetUrl),callback:function ($$v) {_vm.$set(_vm.form, "targetUrl", $$v)},expression:"form.targetUrl"}}),_c('span',{staticClass:"info"},[_vm._v("(例如：\"/futureCreateWork/home\")")])],1)]:_vm._e(),(_vm.form.targetType == 3)?[_c('el-form-item',{attrs:{"label":"第三方h5页面路径","rules":[
            {
              required: true,
              message: '请输入第三方h5页面路径',
              trigger: 'blur',
            },
          ],"prop":"targetUrl"}},[_c('v-input',{attrs:{"placeholder":"请输入第三方h5页面路径","width":350,"disabled":""},model:{value:(_vm.form.targetUrl),callback:function ($$v) {_vm.$set(_vm.form, "targetUrl", $$v)},expression:"form.targetUrl"}}),_c('span',{staticClass:"info"},[_vm._v("(例如：\"https://dev.zhuneng.cn/zlb-h5-yljk/pages/entrance/thirdParty\")")])],1)]:_vm._e(),(_vm.form.targetType == 4)?[_c('el-form-item',{attrs:{"label":"第三方小程序appid","rules":[
            {
              required: true,
              message: '请输入第三方小程序appid',
              trigger: 'blur',
            },
          ],"prop":"targetAppid"}},[_c('v-input',{attrs:{"placeholder":"请输入第三方小程序appid","disabled":"","width":250},model:{value:(_vm.form.targetAppid),callback:function ($$v) {_vm.$set(_vm.form, "targetAppid", $$v)},expression:"form.targetAppid"}}),_c('span',{staticClass:"info"},[_vm._v("(例如：\"wx0cd4b682f7e7f72f\")")])],1),_c('el-form-item',{attrs:{"label":"第三方小程序页面路径","rules":[
            {
              required: true,
              message: '请输入第三方小程序页面路径',
              trigger: 'blur',
            },
          ],"prop":"targetUrl"}},[_c('v-input',{attrs:{"placeholder":"请输入第三方小程序页面路径","disabled":"","width":350},model:{value:(_vm.form.targetUrl),callback:function ($$v) {_vm.$set(_vm.form, "targetUrl", $$v)},expression:"form.targetUrl"}}),_c('span',{staticClass:"info"},[_vm._v("(例如：\"/pages/home/home?XKHospitalCode=XK33021201fGdxixsF\")")])],1)]:_vm._e(),_c('el-form-item',{attrs:{"label":"应用的管理后台页面","rules":[
          {
            required: false,
            message: '请输入应用的管理后台页面路径',
            trigger: 'blur',
          },
        ],"prop":"managerUrl"}},[_c('v-input',{attrs:{"placeholder":"请输入应用的管理后台页面路径","width":350,"disabled":""},model:{value:(_vm.form.managerUrl),callback:function ($$v) {_vm.$set(_vm.form, "managerUrl", $$v)},expression:"form.managerUrl"}})],1),_c('el-form-item',{attrs:{"label":"排序","rules":[{ required: true, message: '请输入排序', trigger: 'blur' }],"prop":"sortIndex"}},[_c('v-input',{attrs:{"placeholder":"请输入排序","width":250,"type":"number"},model:{value:(_vm.form.sortIndex),callback:function ($$v) {_vm.$set(_vm.form, "sortIndex", $$v)},expression:"form.sortIndex"}})],1),_c('el-form-item',{attrs:{"label":"是否推荐","rules":[
          { required: true, message: '请选择是否推荐', trigger: 'blur' },
        ],"prop":"isRecommend"}},[_c('v-select',{attrs:{"options":_vm.isRecommendList},model:{value:(_vm.form.isRecommend),callback:function ($$v) {_vm.$set(_vm.form, "isRecommend", $$v)},expression:"form.isRecommend"}})],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }